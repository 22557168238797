import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated, useSprings } from '@react-spring/web';

const LoadingComponent = () => {
  const navigate = useNavigate();

  // Letras para el efecto de aparición gradual
  const letters = 'Strong Capitals'.split('');

  const [progress, setProgress] = useState(0);

  // Animación de las letras
  const letterSprings = useSprings(
    letters.length,
    letters.map((_, index) => ({
      from: { opacity: 0, filter: 'blur(5px)', transform: 'translateY(20px)' },
      to: { opacity: 1, filter: 'blur(0px)', transform: 'translateY(0)' },
      delay: index * 150,
      config: { tension: 180, friction: 14 },
      onRest: () => {
        const shownLetters = index + 1;
        setProgress(shownLetters / letters.length);
      },
    }))
  );

  // Animación del fondo usando un radial-gradient que se mueve horizontalmente
  // Negro (#000) a verde (#01796F)
  // Y la posición del gradiente radial pasa de circle at 90% 50% a circle at 10% 50%
  const backgroundSpring = useSpring({
    from: { p: 0 },
    to: { p: progress },
    config: { duration: 300 }
  });

  const interpolatedBackground = backgroundSpring.p.to((p) => {
    // Interpolamos color entre negro (#000000) y verde (#01796F)
    const R = Math.round(p * 1);
    const G = Math.round(p * 121);
    const B = Math.round(p * 111);
    const color = `rgb(${R}, ${G}, ${B})`;

    // Posición horizontal del foco del gradiente (de 90% a 10%)
    const xPos = 90 - 80 * p; // p=0 => x=90%, p=1 => x=10%
    // Gradiente radial que se mueve:
    return `radial-gradient(circle at ${xPos}% 50%, ${color}, #000000)`;
  });

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate('/welcome');
    }, 5000);
    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <animated.div style={{ ...styles.container, backgroundImage: interpolatedBackground }}>
      <div style={styles.textContainer}>
        {letterSprings.map((props, index) => (
          <animated.span key={index} style={{ ...styles.letter, ...props }}>
            {letters[index]}
          </animated.span>
        ))}
      </div>
    </animated.div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    overflow: 'hidden',
    padding: '1rem',
    boxSizing: 'border-box',
    backgroundColor: '#000000', // Fondo base por si el gradiente no carga
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  letter: {
    color: '#000000',
    fontSize: 'clamp(2rem, 8vw, 4rem)',
    fontWeight: 'bold',
    letterSpacing: '2px',
    marginRight: '2px',
    textAlign: 'center',
    fontFamily: 'Poppins, sans-serif',
  },
};

export default LoadingComponent;
