// LoginComponent.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useSpring, animated, useTrail } from '@react-spring/web';

const LoginComponent = () => {
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/login`, {
        username,
        password,
      });
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('username', username);
      navigate('/welcome');
    } catch (error) {
      setErrorMessage('Usuario o contraseña incorrectos. Intenta nuevamente.');
    }
  };

  // Animación para el contenedor con un suave degradado
  const containerSpring = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    config: { duration: 1000 },
  });

  const items = [
    <h1 style={styles.header}>Iniciar Sesión</h1>,
    <form onSubmit={handleSubmit} style={styles.form}>
      <div style={styles.inputGroup}>
        <label style={styles.label}>Nombre de Usuario:</label>
        <input
          type="text"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          style={styles.input}
          required
        />
      </div>
      <div style={styles.inputGroup}>
        <label style={styles.label}>Contraseña:</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          style={styles.input}
          required
        />
      </div>
      {errorMessage && <p style={styles.error}>{errorMessage}</p>}
      <button type="submit" style={styles.button}>Iniciar Sesión</button>
    </form>,
  ];

  const trail = useTrail(items.length, {
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0px)' },
    config: { tension: 200, friction: 20 },
    delay: 300,
  });

  return (
    <animated.div style={{ ...styles.container, ...containerSpring }}>
      <div style={styles.card}>
        {trail.map((props, index) => (
          <animated.div key={index} style={props}>
            {items[index]}
          </animated.div>
        ))}
      </div>
    </animated.div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    background: 'linear-gradient(135deg, #0d0d0d 0%, #1c1c1c 100%)',
    overflow: 'hidden',
    padding: '1rem',
    boxSizing: 'border-box',
    fontFamily: 'Poppins, sans-serif',
  },
  card: {
    backgroundColor: 'rgba(255,255,255,0.05)',
    backdropFilter: 'blur(10px)',
    borderRadius: '16px',
    padding: '2rem',
    boxShadow: '0 8px 30px rgba(0,0,0,0.4)',
    maxWidth: '400px',
    width: '100%',
    boxSizing: 'border-box',
  },
  header: {
    color: '#FFFFFF',
    fontSize: 'clamp(2rem, 5vw, 3rem)',
    marginBottom: '1.5rem',
    textAlign: 'center',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '1.5rem',
  },
  inputGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.5rem',
  },
  label: {
    color: '#FFFFFF',
    fontFamily: 'Poppins, sans-serif',
    fontWeight: '500',
    fontSize: '1rem',
  },
  input: {
    width: '100%',
    padding: '0.75rem',
    borderRadius: '8px',
    border: '2px solid #444',
    fontSize: '1rem',
    outline: 'none',
    backgroundColor: '#1e1e1e',
    color: '#FFF',
    transition: 'border-color 0.3s ease',
    boxSizing: 'border-box',
  },
  button: {
    width: '100%',
    padding: '0.75rem',
    borderRadius: '8px',
    border: 'none',
    backgroundColor: '#0288d1',
    color: '#FFFFFF',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    fontFamily: 'Poppins, sans-serif',
    fontSize: '1rem',
  },
  error: {
    color: 'red',
    textAlign: 'center',
    fontSize: '0.875rem',
  },
};

export default LoginComponent;
