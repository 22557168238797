// PortfolioComponent.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Pie } from 'react-chartjs-2';
import { useNavigate } from 'react-router-dom';
import { Chart, ArcElement, Tooltip, Legend } from 'chart.js';
import Confetti from 'react-confetti';

Chart.register(ArcElement, Tooltip, Legend);

const PortfolioComponent = () => {
  const navigate = useNavigate();
  const [portfolio, setPortfolio] = useState([]);
  const [totalBalance, setTotalBalance] = useState(0);

  const [fondoReservaAirdrop, setFondoReservaAirdrop] = useState(0);
  const [fondoPreVentas, setFondoPreVentas] = useState(0);
  const [initialInvestmentFiat, setInitialInvestmentFiat] = useState(0);
  const [midnightValueFiat, setMidnightValueFiat] = useState(0);

  const [showConfetti, setShowConfetti] = useState(true);
  const [fadeOut, setFadeOut] = useState(false);

  const token = localStorage.getItem('token');
  const username = localStorage.getItem('username');

  const getIdentificador = (username) => {
    switch (username?.toLowerCase()) {
      case 'diego': return 'Broda';
      case 'papa': return 'Papito';
      case 'mario': return 'Mario';
      default: return username || 'Usuario';
    }
  };

  const identificador = getIdentificador(username);

  useEffect(() => {
    if (!token) {
      navigate('/login');
    }

    const fetchPortfolio = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL || 'http://localhost:8080'}/portfolio`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const sortedData = response.data.portfolioValue.sort((a, b) => b.valorActual - a.valorActual);
        setPortfolio(sortedData);
        setTotalBalance(response.data.saldoTotal);
        setFondoReservaAirdrop(response.data.fondoReservaAirdrop);
        setFondoPreVentas(response.data.fondoPreVentas);

        if (response.data.initialInvestmentFiat !== undefined) {
          setInitialInvestmentFiat(response.data.initialInvestmentFiat);
        }
        if (response.data.midnightValueFiat !== undefined) {
          setMidnightValueFiat(response.data.midnightValueFiat);
        }

      } catch (error) {
        console.error('Error obteniendo el portafolio:', error);
        navigate('/login');
      }
    };

    fetchPortfolio();
  }, [token, navigate]);

  useEffect(() => {
    const fadeOutTimer = setTimeout(() => {
      setFadeOut(true);
    }, 3000);

    const removeConfettiTimer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000);

    return () => {
      clearTimeout(fadeOutTimer);
      clearTimeout(removeConfettiTimer);
    };
  }, []);

  const pieData = {
    labels: portfolio.map((crypto) => crypto.nombre),
    datasets: [
      {
        data: portfolio.map((crypto) => crypto.valorActual),
        backgroundColor: ['#FF6384','#36A2EB','#FFCE56','#4BC0C0','#9966FF','#FF9F40'],
        hoverBackgroundColor: ['#FF6384','#36A2EB','#FFCE56','#4BC0C0','#9966FF','#FF9F40'],
      },
    ],
  };

  const width = window.innerWidth;
  const height = window.innerHeight;

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    navigate('/login');
  };

  // Cálculo del crecimiento global
  let growthPercentage = 0;
  let growthText = '';
  if (initialInvestmentFiat > 0) {
    growthPercentage = ((totalBalance - initialInvestmentFiat) / initialInvestmentFiat) * 100;
    growthPercentage = Number(growthPercentage.toFixed(2));
    growthText = growthPercentage >= 0
      ? `Esto representa un crecimiento de ${growthPercentage}% respecto a tu inversión inicial`
      : `Esto representa una disminución de ${Math.abs(growthPercentage)}% respecto a tu inversión inicial`;
  }

  // Cálculo del crecimiento diario
  let dailyGrowthText = '';
  let isDailyGrowthPositive = true;
  if (midnightValueFiat > 0) {
    let dailyGrowthPercentage = ((totalBalance - midnightValueFiat) / midnightValueFiat) * 100;
    dailyGrowthPercentage = Number(dailyGrowthPercentage.toFixed(2));
    isDailyGrowthPositive = dailyGrowthPercentage >= 0;
    dailyGrowthText = dailyGrowthPercentage >= 0
      ? `Hoy tu portafolio ha crecido un ${dailyGrowthPercentage}% desde las 00:00`
      : `Hoy tu portafolio ha disminuido un ${Math.abs(dailyGrowthPercentage)}% desde las 00:00`;
  }

  const backgroundStyle = isDailyGrowthPositive
    ? 'linear-gradient(to bottom, #50C878, #000000)'
    : 'linear-gradient(to bottom, #B22222, #000000)';

  return (
    <div style={{ ...styles.container, background: backgroundStyle }}>
      {showConfetti && (
        <div style={{
          ...styles.confettiContainer,
          opacity: fadeOut ? 0 : 1,
          transition: 'opacity 2s ease'
        }}>
          <Confetti
            width={width}
            height={height}
            numberOfPieces={200}
            gravity={0.2}
            confettiSource={{ x: width / 2, y: 0, w: 0, h: 0 }}
            recycle={false}
          />
        </div>
      )}

      <button style={styles.logoutButton} onClick={handleLogout}>Cerrar Sesión</button>

      <h1 style={styles.header}>{identificador}, tu saldo hoy es de ${totalBalance}</h1>

      {initialInvestmentFiat > 0 && (
        <div style={styles.infoContainer}>
          <span>{growthText}</span>
          <span style={styles.infoIcon} title="Este porcentaje se calcula comparando el valor actual de tu portafolio con la inversión inicial en fiat.">i</span>
        </div>
      )}

      <hr style={styles.divider} />

      <div style={styles.fundsLine}>
        <span>Fondo Reserva Airdrop: ${fondoReservaAirdrop}</span>
        <span style={styles.separator}>;</span>
        <span>Fondo Pre-Ventas: ${fondoPreVentas}</span>
      </div>

      <div style={styles.pieContainer}>
        <Pie data={pieData} />
      </div>

      {midnightValueFiat > 0 && (
        <div style={styles.dailyGrowthContainer}>
          <span>{dailyGrowthText}</span>
        </div>
      )}

      <div style={styles.tableContainer}>
        <table style={styles.table}>
          <thead>
            <tr>
              <th style={styles.th}>Nombre</th>
              <th style={styles.th}>Cantidad</th>
              <th style={styles.th}>Valor Actual (USD)</th>
            </tr>
          </thead>
          <tbody>
            {portfolio.map((crypto) => (
              <tr key={crypto.nombre}>
                <td style={styles.td}>{crypto.nombre}</td>
                <td style={styles.td}>{crypto.balance}</td>
                <td style={styles.td}>${crypto.valorActual}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '2rem',
    minHeight: '100vh',
    color: '#FFFFFF',
    boxSizing: 'border-box',
    position: 'relative',
    fontFamily: 'Poppins, sans-serif',
  },
  confettiContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    pointerEvents: 'none',
    zIndex: 9999,
  },
  logoutButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '10px 20px',
    backgroundColor: '#0288d1',
    color: '#FFFFFF',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '1rem',
  },
  header: {
    fontSize: 'clamp(2rem, 5vw, 3rem)',
    marginBottom: '20px',
    textAlign: 'center',
  },
  infoContainer: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '1.2rem',
    marginBottom: '20px',
  },
  infoIcon: {
    marginLeft: '8px',
    backgroundColor: '#333',
    borderRadius: '50%',
    width: '20px',
    height: '20px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'help',
    fontSize: '0.9rem',
    fontWeight: 'bold',
    color: '#FFFFFF'
  },
  divider: {
    width: '60%',
    margin: '20px auto',
    border: '0',
    borderTop: '1px solid #555',
  },
  fundsLine: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    fontSize: '1.2rem',
    marginBottom: '20px',
    justifyContent: 'center',
    alignItems: 'center',
  },
  separator: {
    fontWeight: 'bold',
    fontSize: '1.2rem'
  },
  pieContainer: {
    width: '100%',
    maxWidth: '600px',
    marginBottom: '30px',
  },
  dailyGrowthContainer: {
    marginBottom: '30px',
    fontSize: '1.2rem',
    textAlign: 'center',
  },
  tableContainer: {
    width: '100%',
    maxWidth: '800px',
    marginBottom: '30px',
  },
  table: {
    width: '100%',
    borderCollapse: 'collapse',
    backgroundColor: '#1e1e1e',
  },
  th: {
    border: '1px solid #333',
    padding: '10px',
    backgroundColor: '#333',
    color: '#FFF',
  },
  td: {
    border: '1px solid #333',
    padding: '10px',
    textAlign: 'center',
  },
};

export default PortfolioComponent;
