// App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './index.css'; // Importa tu CSS global aquí
import LoadingComponent from './components/LoadingComponent';
import LoginComponent from './components/LoginComponent';
import PortfolioComponent from './components/PortfolioComponent';
import WelcomeComponent from './components/WelcomeComponent';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LoadingComponent />} />
        <Route path="/login" element={<LoginComponent />} />
        <Route path="/welcome" element={<WelcomeComponent />} />
        <Route path="/portfolio" element={<PortfolioComponent />} />
      </Routes>
    </Router>
  );
}

export default App;
