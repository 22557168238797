import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSpring, animated, easings } from '@react-spring/web';

// Función para convertir un color hex a RGB
function hexToRgb(hex) {
  const cleanHex = hex.replace('#', '');
  const r = parseInt(cleanHex.substring(0,2),16);
  const g = parseInt(cleanHex.substring(2,4),16);
  const b = parseInt(cleanHex.substring(4,6),16);
  return {r,g,b};
}

const WelcomeComponent = () => {
  const navigate = useNavigate();
  const [username] = useState(() => localStorage.getItem('username') || '');

  const vibrantColors = useMemo(() => [
    { backgroundColor: '#1E90FF', textColor: '#FFFFFF' },
    { backgroundColor: '#DC143C', textColor: '#FFFFFF' },
    { backgroundColor: '#8A2BE2', textColor: '#FFFFFF' },
    { backgroundColor: '#FF8C00', textColor: '#000000' },
    { backgroundColor: '#32CD32', textColor: '#000000' },
  ], []);

  const [selectedColor, setSelectedColor] = useState(vibrantColors[0]);

  useEffect(() => {
    if (!username) {
      navigate('/login');
    }
  }, [navigate, username]);

  useEffect(() => {
    const randomColor = vibrantColors[Math.floor(Math.random() * vibrantColors.length)];
    setSelectedColor(randomColor);
  }, [vibrantColors]);

  const getGreeting = (username) => {
    switch (username.toLowerCase()) {
      case 'diego': return 'Hola, Broda!';
      case 'papa': return 'Hola, Papito!';
      case 'mario': return 'Hola, Mario!';
      case 'adriana': return 'Hola!, Adriana';
      case 'user1': return 'Hola!, user1';
      case 'david y cony': return 'Hola, hermosura! <3';
      case 'valentina': return 'Hola, Valentina!';
      default: return `Hola, ${username}!`;
    }
  };

  useEffect(() => {
    if (username) {
      const timer = setTimeout(() => {
        navigate('/portfolio');
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [navigate, username]);

  const { scale, opacity, grad } = useSpring({
    from: { scale: 0.8, opacity: 0.9, grad: 0 },
    to: { scale: 1, opacity: 1, grad: 1 },
    config: { duration: 700, easing: easings.easeInOutQuad }
  });

  // Interpolar el color desde negro (#000000) a selectedColor.backgroundColor
  const baseColor = {r:0,g:0,b:0}; // negro
  const targetRGB = hexToRgb(selectedColor.backgroundColor);

  const interpolatedBackground = grad.to(p => {
    const R = Math.round(baseColor.r + (targetRGB.r - baseColor.r) * p);
    const G = Math.round(baseColor.g + (targetRGB.g - baseColor.g) * p);
    const B = Math.round(baseColor.b + (targetRGB.b - baseColor.b) * p);
    const finalColor = `rgb(${R}, ${G}, ${B})`;
    // Gradiente vertical: de negro a finalColor
    // Añadimos una viñeta suave usando radial-gradient superpuesto
    // Podemos combinar con una coma: primero el degrade vertical, luego una viñeta sutil
    // Ej: radial-gradient(circle at center, rgba(0,0,0,0.1) 75%, transparent 100%), linear-gradient(to bottom, #000000, finalColor)
    return `radial-gradient(circle at center, rgba(0,0,0,0.1) 75%, transparent 100%), linear-gradient(to bottom, #000000, ${finalColor})`;
  });

  return (
    <animated.div style={{
      ...styles.container,
      backgroundImage: interpolatedBackground,
      transform: scale.to(s => `scale(${s})`),
      opacity: opacity
    }}>
      <h1 style={{
        ...styles.text,
        color: selectedColor.textColor
      }}>
        {getGreeting(username)}
      </h1>
    </animated.div>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    overflow: 'hidden',
    padding: '1rem',
    boxSizing: 'border-box',
    borderRadius: '12px', // Sutil borde redondeado
    // El gradiente ya está en backgroundImage, no backgroundColor
  },
  text: {
    fontSize: 'clamp(2rem, 5vw, 3rem)',
    fontWeight: 'bold',
    fontFamily: 'Poppins, sans-serif',
    textAlign: 'center',
  },
};

export default WelcomeComponent;
